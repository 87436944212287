// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import * as service from '../../../services/service';

function MechanicalDivision() {
    const [state, setState] = useState({ mechanicaldivision: '' });
    const [CEData, setCEData] = useState([]);
    const [SEData, setSEData] = useState([]);
    const [CEId, setCEId] = useState('');
    const [SEId, setSEId] = useState('');
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getCEs = () => {
        fetch(
            service.CE_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.ceId, label: item.ce }));
                setCEData(data2);
            });
    };

    const getSEs = (id) => {
        fetch(
            service.GET_SE_BY_CE_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CEId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.seId, label: item.se }));
                setSEData(data2);
            });
    };
    const getDetails = () => {
        const errs = {};
        fetch(
            service.MECHANICAL_DIVISION_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getDetailsById = (id) => {
        setLoading(true);
        const errs = {};
        fetch(
            service.GET_MECHANICAL_DIVISION_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    MechanicalDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    setState({ ...state, mechanicaldivision: result.mechanicalDivision });

                    const ce = ({ value: result.ceId, label: result.ce });
                    setCEId(ce);
                    const se = ({ value: result.seId, label: result.se });
                    setSEId(se);
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }

            });
        setLoading(false);
    };

    const handleEdit = (id) => {
        getDetailsById(id);
        localStorage.setItem("ID", JSON.stringify(id));
    }

    useEffect(() => {
        setLoading(true);
        getCEs();
        getDetails();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!CEId) {
            isValid = false;
            errs[".ce"] = "Please select CE";
        }
        if (!SEId) {
            isValid = false;
            errs[".se"] = "Please select SE";
        }
        if (!state.mechanicaldivision) {
            isValid = false;
            errs[".mechanicaldivision"] = "Please enter Mechanical Division";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const CEChangeHandler = (ev) => {
        setCEId(ev);
        getSEs(ev.value);
    }
    const SEChangeHandler = (ev) => {
        setSEId(ev);        
    }

    const clearHandler = () => {
        localStorage.setItem('ID', null);
        setState({ ...state, se: '' });
        const ce = ({ value: null, label: "Select CE" });
        setCEId(ce);
        const se = ({ value: null, label: "Select SE" });
        setSEId(se);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_MECHANICAL_DIVISION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        MechanicalDivisionId: JSON.parse(localStorage.getItem('ID')) !== null ? JSON.parse(localStorage.getItem('ID')) : 0,
                        MechanicalDivision: state.mechanicaldivision,
                        SEId: SEId.value,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        localStorage.setItem('ID', null);
                        window.location.reload();
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Mechanical Division
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox pt={4} pb={3} px={3} style={{ width: '50%' }}>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="CE"
                                                options={CEData} placeholder="Select CE"
                                                value={CEId}
                                                onChange={CEChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ce"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="SE"
                                                options={SEData} placeholder="Select SE"
                                                value={SEId}
                                                onChange={SEChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".se"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Mechanical Division" fullWidth value={state.mechanicaldivision || ''}
                                                name="mechanicaldivision"
                                                onChange={changeHandler}
                                                id="mechanicaldivision"
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".mechanicaldivision"]}</div>
                                        </MDBox>
                                        <MDBox mt={4} mb={1} textAlign="center">
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <div>
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={clearHandler}>
                                                        CLEAR
                                                    </MDButton>
                                                </div>
                                            }
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold' }}>CE</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>SE</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Mechanical Division</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.bankId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.ce}</TableCell>
                                                        <TableCell component="th" scope="row">{row.se}</TableCell>
                                                        <TableCell component="th" scope="row">{row.mechanicalDivision}</TableCell>
                                                        <TableCell><MDButton onClick={() => handleEdit(row.mechanicalDivisionId)}>Edit</MDButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default MechanicalDivision;