// import { useNavigate } from 'react-router-dom';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import MDButton from 'components/MDButton';
import MDInput from "components/MDInput";
import { Oval } from 'react-loader-spinner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from 'react-select';
import * as service from '../../../services/service';

function CivilSubDivision() {
    const [state, setState] = useState({ civilsubdivision: '' });
    const [CEOData, setCEOData] = useState([]);
    const [SEOData, setSEOData] = useState([]);
    const [CivilDivisionData, setCivilDivisionData] = useState([]);
    const [CEOId, setCEOId] = useState('');
    const [SEOId, setSEOId] = useState('');
    const [CivilDivisionId, setCivilDivisionId] = useState('');
    const [data, setData] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };

    const getCEOs = () => {
        fetch(
            service.CIVIL_CEO_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilCEOId, label: item.civilCEO }));
                setCEOData(data2);
            });
    };

    const getSEOs = (id) => {
        fetch(
            service.GET_CIVIL_SEO_BY_CEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilCEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilSEOId, label: item.civilSEO }));
                setSEOData(data2);
            });
    };

    const getCivilDivisions = (id) => {
        fetch(
            service.GET_CIVIL_DIVISION_BY_SEO_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilSEOId: id,
                })
            })
            .then(res => res.json())
            .then(result => {
                const data2 = result.map((item) => ({ value: item.civilDivisionId, label: item.civilDivision }));
                setCivilDivisionData(data2);
            });
    };
    const getDetails = () => {
        const errs = {};
        fetch(
            service.CIVIL_SUB_DIVISION_URL,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then(result => {
                setData(result);
            })
            .catch(err => {
                if (err) {
                    errs[".invalid"] = err;
                    setErrors(err);
                };
            });
    };

    const getDetailsById = (id) => {
        setLoading(true);
        const errs = {};
        fetch(
            service.GET_CIVIL_SUB_DIVISION_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    CivilSubDivisionId: id,
                })
            })
            .then(res => res.json())
            .then(result => {               
                if (result.message.messageType === 'Success') {
                    setState({ ...state, civilsubdivision: result.civilSubDivision });

                    const ceo = ({ value: result.civilCEOId, label: result.civilCEO });
                    setCEOId(ceo);
                    getSEOs(ceo.value);
                    const seo = ({ value: result.civilSEOId, label: result.civilSEO });
                    setSEOId(seo);
                    getCivilDivisions(seo.value);
                    const div = ({ value: result.civilDivisionId, label: result.civilDivision });
                    setCivilDivisionId(div);
                }
                else {
                    errs[".invalid"] = result.message.message;
                    setErrors(errs);
                }

            });
        setLoading(false);
    };

    const handleEdit = (id) => {
        getDetailsById(id);
        localStorage.setItem("ID", JSON.stringify(id));
    }

    useEffect(() => {
        setLoading(true);
        getCEOs();
        getDetails();
        setLoading(false);
    }, []);

    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!CEOId) {
            isValid = false;
            errs[".ceo"] = "Please select Civil CEO";
        }
        if (!SEOId) {
            isValid = false;
            errs[".seo"] = "Please select Civil SEO";
        }
        if (!CivilDivisionId) {
            isValid = false;
            errs[".civildivision"] = "Please select Civil Division";
        }
        if (!state.civilsubdivision) {
            isValid = false;
            errs[".civilsubdivision"] = "Please enter Civil Sub Division";
        }
        setErrors(errs);
        return isValid;
    }
    const changeHandler = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    };
    const CEOChangeHandler = (ev) => {
        setCEOId(ev);
        getSEOs(ev.value);
    }
    const SEOChangeHandler = (ev) => {
        setSEOId(ev);
        setCivilDivisionData([]); 
        getCivilDivisions(ev.value);       
    }
    const CivilDivisionChangeHandler = (ev) => {
        setCivilDivisionId(ev);         
    }

    const clearHandler = () => {
        localStorage.setItem('ID', null);
        setState({ ...state, civilsubdivision: '' });
        const ceo = ({ value: null, label: "Select CEO" });
        setCEOId(ceo);
        const seo = ({ value: null, label: "Select SEO" });
        setSEOId(seo);
        const div = ({ value: null, label: "Select Division" });
        setCivilDivisionId(div);
    }

    const submitHandler = e => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            fetch(
                service.ADD_UPDATE_CIVIL_SUB_DIVISION,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        CivilSubDivisionId: JSON.parse(localStorage.getItem('ID')) !== null ? JSON.parse(localStorage.getItem('ID')) : 0,
                        CivilSubDivision: state.civilsubdivision,
                        CivilDivisionId: CivilDivisionId.value,
                        AddedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                        UpdatedBy: JSON.parse(sessionStorage.getItem('userData')).userId,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        localStorage.setItem('ID', null);
                        window.location.reload();
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                            >
                                <MDTypography variant="h6" color="white">
                                    Civil Sub Division
                                </MDTypography>
                            </MDBox>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                <MDBox pt={4} pb={3} px={3} style={{ width: '50%' }}>
                                    <MDBox component="form" role="form">
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="CEO"
                                                options={CEOData} placeholder="Select CEO"
                                                value={CEOId}
                                                onChange={CEOChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".ceo"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="SEO"
                                                options={SEOData} placeholder="Select SEO"
                                                value={SEOId}
                                                onChange={SEOChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".seo"]}</div>
                                        </MDBox>
                                        <MDBox mb={2} style={{ fontSize: '14px' }}>
                                            <Select
                                                name="Civil Division"
                                                options={CivilDivisionData} placeholder="Select Division"
                                                value={CivilDivisionId}
                                                onChange={CivilDivisionChangeHandler}
                                                isSearchable
                                                styles={selectStyles}
                                            />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".civildivision"]}</div>
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <MDInput label="Civil Sub Division" fullWidth value={state.civilsubdivision || ''}
                                                name="civilsubdivision"
                                                onChange={changeHandler}
                                                id="civilsubdivision"
                                                required />
                                            <div style={{ fontSize: '12px', color: 'red' }}>{errors[".civilsubdivision"]}</div>
                                        </MDBox>
                                        <MDBox mt={4} mb={1} textAlign="center">
                                            {loading ? <Oval
                                                heigth="20"
                                                width="20"
                                                color='grey'
                                                ariaLabel='loading'
                                            /> :
                                                <div>
                                                    <MDButton color="info" onClick={submitHandler}>
                                                        SAVE
                                                    </MDButton>&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton color="info" onClick={clearHandler}>
                                                        CLEAR
                                                    </MDButton>
                                                </div>
                                            }
                                            {errors ? <div style={{ fontSize: '12px', color: 'red' }}>{errors[".invalid"]}</div> : null}
                                        </MDBox>
                                    </MDBox>
                                </MDBox>
                            </Card>
                            <br />
                            <Card style={{ alignItems: 'center' }}>
                                {loading ? <Oval
                                    heigth="100"
                                    width="100"
                                    color='grey'
                                    ariaLabel='loading'
                                /> :
                                    <TableContainer component={Paper} style={{ width: '80%', alignSelf: 'center', }}>
                                        <Table aria-label="simple table">
                                            <TableRow style={{ backgroundColor: 'lightgrey' }}>
                                                <TableCell style={{ fontWeight: 'bold' }}>Civil CEO</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Civil SEO</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Civil Division</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Civil Sub Division</TableCell>
                                                <TableCell style={{ fontWeight: 'bold' }}>Action</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((row) => (
                                                    <TableRow
                                                        key={row.bankId}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">{row.civilCEO}</TableCell>
                                                        <TableCell component="th" scope="row">{row.civilSEO}</TableCell>
                                                        <TableCell component="th" scope="row">{row.civilDivision}</TableCell>
                                                        <TableCell component="th" scope="row">{row.civilSubDivision}</TableCell>
                                                        <TableCell><MDButton onClick={() => handleEdit(row.civilSubDivisionId)}>Edit</MDButton></TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                }
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <div style={{ position: 'absolute', left: 0, right: 0, bottom: 0 }}>
                <Footer />
            </div>
        </DashboardLayout>
    );
}
export default CivilSubDivision;